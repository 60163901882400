<template>
  <div class="addressDialog">
    <el-dialog top="3%" width="50%" :title="dialogTitle" :visible.sync="visible" :close-on-click-modal="false"
      :before-close="closeEvent">
      <el-form ref="dialogForm" :rules="rules" :model="form" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="店铺名称" prop="shopName">
              <el-input v-model="form.shopName" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="phoneNumber">
              <el-input type="text" maxlength="11" v-model="form.phoneNumber" placeholder="请输入手机号码">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归属用户" prop="openId">
              <el-select v-model="form.openId" placeholder="请选择归属用户" style="width: 100%">
                <el-option :label="item.label" :value="item.value" v-for="item in userList" :key="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="form.realName" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地区选择" prop="selectedOptions">
              <el-cascader size="large" :options="pcaTextArr" v-model="form.selectedOptions" style="width: 100%">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="详细地址" prop="detail">
              <el-input type="textarea" :rows="6" placeholder="请输入详细地址" v-model="form.detail">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pcaTextArr } from "element-china-area-data";
export default {
  data() {
    return {
      pcaTextArr,
      visible: false,
      dialogTitle: "新增地址",
      userList: [],
      form: {
        shopName: "",
        phoneNumber: "",
        province: "",
        city: "",
        county: "",
        detail: "",
        openId: "",
        selectedOptions: [],
      },
      rules: {
        shopName: [
          { required: true, message: "请输入店铺名称", trigger: "change" },
        ],
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "change" },
        ],
        openId: [
          { required: true, message: "请选择归属用户", trigger: "change" },
        ],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "change" },
        ],
        selectedOptions: [
          { required: true, message: "请输入地区选择", trigger: "change" },
        ],
        detail: [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    submitData() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let params = { ...this.form };
          params.province = this.form.selectedOptions[0];
          params.city = this.form.selectedOptions[1];
          params.county = this.form.selectedOptions[2];
          let url = "";
          if (params.id) {
            //修改
            url = "api/selfPoint/update";
          } else {
            url = "api/selfPoint/add";
          }

          this.getAjax(url, params, "post").then(() => {
            this.$message.success("操作成功");
            this.closeEvent();
            this.$emit("search");
          });
        }
      });
    },
    closeEvent() {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.visible = false;
      this.dialogTitle = "新增地址";
      this.form = {};
    },
    getInfo(id) {
      this.dialogTitle = "编辑地址";
      this.getAjax("api/selfPoint/details?id=" + id, {}, "get").then((res) => {
        this.form = res.data.info;
        this.form.selectedOptions = [
          this.form.province,
          this.form.city,
          this.form.county,
        ];
        this.visible = true;
      });
    },
  },
  mounted() {
    //获取所有用户
    this.getAjax("api/allUser", {}, "get").then((res) => {
      this.userList = res.data.list.map((e) => {
        return {
          label: e.nickName,
          value: e.id,
        };
      });
    });
  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
